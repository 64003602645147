// LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <video autoPlay muted loop className="video-background">
        <source src={process.env.PUBLIC_URL + '/videos/background.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="header">
        <Link to="/signup" className="text-button">SportyText</Link>
        <div className="text-button">Account</div>
      </div>
      <div className="content">
        <h1>Know When The Game's On</h1>
        <p>We text you when your favorite team is playing</p>
        <p>It's Free (seriously)</p>
        <Link to="/signup" className="signup-button">Sign Up</Link>
      </div>
    </div>
  );
};

export default LandingPage;
