import React, { useState } from 'react';
import './SignUpPage.css';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: 'us-east-2_qggbatcvm',
  ClientId: '1e2hfnooar2p7o17cqd0ttq4du'
});

const SignUpPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleConfirmationCodeChange = (e) => {
    setConfirmationCode(e.target.value);
  };

  const handleSignUp = () => {
    const countryCode = "1";
    const username = `+${countryCode}${phoneNumber}`;
    const attributeList = [];

    // Add phone number attribute
    const dataPhoneNumber = {
      Name: 'phone_number',
      Value: username
    };

    const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
    attributeList.push(attributePhoneNumber);

    userPool.signUp(username, 'dummyPassword', attributeList, null, (err, result) => {
      if (err) {
        console.error(err);
        return;
      }

      console.log('User signed up:', result.user);
    });
  };

  const handleConfirmation = () => {
    const userData = {
      Username: phoneNumber,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        console.error(err);
        return;
      }

      console.log('User confirmed registration:', result);
    });
  };

  return (
    <div className="sign-up-page">
      {confirmationCode ? (
        <div className="confirmation-section">
          <h1>Enter Confirmation Code</h1>
          <input
            type="text"
            placeholder="Confirmation Code"
            value={confirmationCode}
            onChange={handleConfirmationCodeChange}
          />
          <button className="continue-button" onClick={handleConfirmation}>
            Verify
          </button>
        </div>
      ) : (
        <div className="sign-up-section">
          <h1>Enter Your Phone Number</h1>
          <p className="subtext">We text you game times and channels</p>
          <input
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <br />
          <button className="continue-button" onClick={handleSignUp} disabled={!phoneNumber}>
            Sign Up
          </button>
          <p className="small-text">
            Joining means you agree to our Terms and Privacy Policy, and to get several messages per
            week. Data rates may apply. Text STOP to cancel, HELP for help.
          </p>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
